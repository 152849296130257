import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import { postRegisterUser } from '../../actions/auth';
import { postLoginUser } from '../../actions/auth';


import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import ListItem from '@mui/material/ListItem'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import backgroundImage from "../../img/LEVLR-Login-Background.jpg";
import levlr_logo from "../../img/LEVLR Logo_CMYK.png";
import Mailgun from 'mailgun.js';

const RegisterForm = props => {

    const mailgun = new Mailgun(FormData);
    const mg = mailgun.client({username: 'Jeffrey Diem', key: process.env.MAILGUN_API_KEY || '57d0666e33636e4c3b02205878d3dc2b-1b3a03f6-45256524'});

    const [credentials, setCredentials] = useState({})
    //const [creds, setCreds] = useState({})
    //console.log("credentials: " + JSON.stringify(credentials,null,2))

    //console.log("creds: " + JSON.stringify(creds,null,2))
    const isRegistered = useSelector(state => state.isRegistered)
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    //const userData = useSelector(state => state.user)
    //console.log("userData: " + JSON.stringify(userData,null,2))

    //console.log("registered?: " + isRegistered)
    //console.log("loggedIn?: " + isLoggedIn)

    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    localStorage.setItem('token', '');
    const [match, setMatch] = useState(false)
    const [validUserName, setValidUserName] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    //console.log("credentials: " + JSON.stringify(credentials,null,2))

    useEffect(() => {
        if (isRegistered) { 
            loginUser()

            if(isLoggedIn) {
                navigate('/accounts')
            }
        }
        //const script = document.createElement("script")
        //script.src = "https://www.google.com/recaptcha/api.js?render=6LcKIcMlAAAAAElqn5HEHgswz5totpW0n-zMWXfq"
        //script.addEventListener("load", handleLoaded)
        //document.body.appendChild(script)
        validatePassword()
        validateConfirm()
        validateEmail()
        //console.log("userName: " + JSON.stringify(credentials,null,2))
        //console.log("match: " + match)
    },[isRegistered, isLoggedIn, navigate, match])


    const handleChange = e => {
        setCredentials({ ...credentials, 
            resetPasswordToken: "",
            resetPasswordExpires: new Date,
            [e.target.name]: (e.target.value).trim()})

        validateConfirm()
    }

    const handleConfirm = e => {
        if (e.target.value === document.getElementById('password').value) {
            setMatch(true)
        }
        else {
            setMatch(false)
        }
    }   

    function validateConfirm() {

        if((document.getElementById('password') === null) && (document.getElementById('confirm') === null)) {
            setMatch(false)
            return false
        }
        if((document.getElementById('password') === null) || (document.getElementById('confirm') === null)) {
            setMatch(false)
            return false
        }
        if((document.getElementById('password') === undefined) && (document.getElementById('confirm') === undefined)) {
            setMatch(false)
            return false
        }
        if((document.getElementById('password') === undefined) || (document.getElementById('confirm') === undefined)) {
            setMatch(false)
            return false
        }
        else {
            if (document.getElementById('password').value === document.getElementById('confirm').value) {
                //console.log("document.getElementById('password').value"+document.getElementById('password').value)
                //console.log("document.getElementById('confirm').value"+document.getElementById('confirm').value)

                setMatch(true)
                return true
            }
            if (document.getElementById('password').value != document.getElementById('confirm').value) {
                //console.log("document.getElementById('password').value"+document.getElementById('password').value)
                //console.log("document.getElementById('confirm').value"+document.getElementById('confirm').value)

                setMatch(false)
                return false
            }
            else {
                setMatch(false)
                return false
            }
        }
    }

    function checkUndefined(x) {
        if(x === undefined || x === null || x === "") {
            return "User Did Not Provide."
        }
        else {
            return x
        }
    }

    function validatePassword() {
        if (document.getElementById('password') === null) {
            return false
        }
        else {
            var p = document.getElementById('password').value,
            errors = [];
            if (p.length < 10) {
                errors.push("10 characters. \n");
            }
            if (p.search(/[a-z]/) < 0) {
                errors.push("1 lowercase letter. \n"); 
            }
            if (p.search(/[A-Z]/) < 0) {
                errors.push("1 uppercase letter. \n"); 
            }
            if (p.search(/[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/i) < 0) {
                errors.push("1 special character. \n"); 
            }
            {/*
            if (p.search(/[0-9]/) < 0) {
                errors.push("1 digit. \n");
            }
            */}
            if (errors.length > 0) {
                return (errors)
            }
        return true
        }
    }

    function validateEmail(){
        if(credentials.email != undefined || credentials.email != null) {
            if(validator.isEmail(credentials.email)) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }
  
    function validateUserName() {
        if(credentials.name != undefined && credentials.name.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    //console.log("test")
    function allowSignUp() {
        if ((match) && (validatePassword() === true) && validateEmail() && validateUserName()) {
            return loginButtonStyle
        }
        else {
            return loginButtonStyleDisabled
        }
    }

    const registerUser = async (e) => {
        localStorage.setItem('user', '');
        e.preventDefault()
        dispatch(postRegisterUser({ props, credentials }))
        
    }

    function renderPasswordRequirements() {
        if(validatePassword() === undefined) {
            return
        }
        if(validatePassword().length != undefined) {
            return (
                <div style={requirementStyles}>
                    {validatePassword().map((req, i) =>
                        <span style={noPadding} key={i}>{req}</span>
                    )}
                </div>
            )
        }
    }

    const routeChange = () =>{ 
        let path = `/login`; 
        navigate(path);
    }

    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
            .execute("6LcKIcMlAAAAAElqn5HEHgswz5totpW0n-zMWXfq", { action: "register" })
            .then(token => {
                // ...
            })
        })
    }

    const loginUser = () => {
        //e.preventDefault()
        dispatch(postLoginUser({ props, credentials }))
    }; 

    function checkPassword(){
        //console.log("validatePassword(): " + validatePassword())
        if(validatePassword().length > 0) {
            return true
        }
        else{
            return false
        }
    }
    
    const backGroundStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        paddingBottom: '10%',
        paddingTop: '10%'
    }

    const noPadding = {
        padding: '0 0',
        margin: '0 0'
    }

    const loginPaperStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        width: '70%',
        height: 'auto',
        paddingTop: '4vh',
        paddingBottom: '4vh',
        marginLeft: 'auto',
        marginRight: 'auto'
    }

    const loginTextFieldStyle = {
        width: '65%',
        height: 'auto'
    }

    const loginFormStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 1,
    }

    const loginButtonStyle = {
        width: '50%',
        color: "white",
        backgroundColor: "#2fb7b7",
        '&:hover': {
            backgroundColor: '#01656a',
        },
        margin: 16,
    }

    const loginButtonStyleDisabled = {
        pointerEvents: 'none',
        width: '50%',
        color: "white",
        backgroundColor: "#01656a",
        margin: 16,
    }

    const returnButtonStyle = {
        width: '50%',
        color: "white",
        backgroundColor: "#01656a",
        '&:hover': {
            backgroundColor: '#2fb7b7',
        },
        margin: 16,
        fontSize: '70%',
        whiteSpace: 'nowrap'
    }

    const levlrLogoStyle = {
        width: '40%',
        height: 'auto'
    }

    const requirementStyles = {
        padding: '0 0',
        margin: '0 0',
        display: 'inline',
        width: '65%',
        height: 'auto',
        textAlign: 'left',
        fontSize: '1.75vh',
        color: '#d32f2f'
    }    

    return (
       
        <div className="Background-Container" style={backGroundStyle}>
            <Container 
                className="g-recaptcha"
                data-sitekey="6LcKIcMlAAAAAElqn5HEHgswz5totpW0n-zMWXfq"
                data-size="invisible"
                component="main" 
                maxwidth="xs">
                <CssBaseline />
                <Paper style={loginPaperStyle}>
                    <img src={levlr_logo} style={levlrLogoStyle} alt="Levlr Logo"/>
                    <form style={loginFormStyle} noValidate onSubmit={(e) => registerUser(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        id="name"
                        label="First Name"
                        name="name"
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                        error={!validateUserName()}
                        helperText={validateUserName() ? ' ' : 'Not a valid First Name.'}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                        error={!validateUserName()}
                        helperText={validateUserName() ? ' ' : 'Not a valid Last Name.'}
                    />
                       <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        id="email"
                        label="email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                        error={!validateEmail()}
                        helperText={validateEmail() ? ' ' : 'Not a valid email.'}

                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        size="small"
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        helperText={renderPasswordRequirements()}
                        onChange={handleChange}
                        error={checkPassword()}
                        style={loginTextFieldStyle}
                    />
                    
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        id="confirm"
                        label="Confirm Password"
                        name="password"
                        type="password"
                        style={loginTextFieldStyle}
                        onChange={handleConfirm}
                        error={!match}
                        helperText={match ? ' ' : 'Passwords Do Not Match.'}
                    />

                    <FormControl variant="outlined" margin="normal" size="small" style={loginTextFieldStyle}>
                        <InputLabel id="acc_type-label">Contractor or Adjuster?</InputLabel>
                        <Select
                            labelId="acc_type-label"
                            id="acc_type"
                            name="acc_type"
                            required
                            onChange={handleChange}
                            label="Contractor or Adjuster?"
                        >
                            <MenuItem value="Contractor">Contractor</MenuItem>
                            <MenuItem value="Adjuster">Adjuster</MenuItem>
                        </Select>
                    </FormControl>
                
                    <Button
                        type="submit"
                        variant="contained"
                        style={allowSignUp()}
                    >
                        Sign Up
                    </Button>
                    Looking for admin access?
                    <Button
                        type="submit"
                        variant="contained"
                        style={returnButtonStyle}
                        onClick={routeChange}
                    >
                        Return to Login
                    </Button>
                    </form>

                </Paper>
            </Container>
            
        </div>
    )
}

export default RegisterForm