// Frontend code using Stripe Elements
import React, { useEffect, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import "../../css/App.css";
import { useSelector, useDispatch } from 'react-redux';

const SubscriptionForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('')
  const userData = useSelector(state => state.user)
  const [hasSubscription, setHasSubscription] = useState(false)
  const [customer, setCustomer] = useState();
  const [customerResponded, setCustomerResponded] = useState(false)
  const [subscriptionResponded, setSubscriptionResponded] = useState(false)
  const [subscription, setSubscription] = useState();
  const [couponCode, setCouponCode] = useState(userData?.coupon)
  const [comps, setComps] = useState()
  const [comparisonsResponded, setComparisonsResponded] = useState(false)
  const [setToCancel, setSetToCancel] = useState(false)
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState("")
  const [subscribeClicked, setSubscribeClicked] = useState(false)
  const [calculateResponded, setCalculateResponded] = useState(false)
  const [count, setCount] = useState(0)  //console.log("userData: " + JSON.stringify(userData, null, 2))
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("subscribeclicked: " + subscribeClicked)
    if(!customerResponded) {
      getCustomerDetails(userData.email)
    }
    if(!comparisonsResponded) {
      getResults(userData._id)
    }
    if(subscription?.data[0] && calculateResponded){
      updateUserSubscription()
    }
    if(!calculateResponded && comparisonsResponded) {
      console.log("calculateAdditionalBill")
      calculateAdditionalBill()
    }
    //console.log("Customer: " + JSON.stringify(customer,null,2))
    //console.log("userData: " + JSON.stringify(userData,null,2))
  },  [userData, dispatch, customerResponded, subscriptionResponded, calculateResponded, amount, description, count, comps, couponCode])

  const setStripeCustomer = async () => {
    //console.log("userData: " + JSON.stringify(userData,null,2))

    try {
      
      const response = await axios.put(`${process.env.REACT_APP_SET_STRIPE_CUSTOMER}`, { email: userData.email });
  
      //console.log("setStripe response:", JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error("Error setting Stripe customer:", error.message);
    }
  };
  const unsetColor = {
    color: "#555555"
  }

  /* const setBaseSubscription = async (id, subscription) => {
    try {
      // Make sure subscription is provided
      if (!subscription) {
        throw new Error('subscription data is required');
      }
      if (subscription.length === 0) {
        subscription = {subscription: "not found"}
      }
  
      const response = await axios.put(`${process.env.REACT_APP_SET_BASE_SUBSCRIPTION}/${id}`, { subscription });
  
      //console.log("setStripe response:", JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error("Error setting Stripe subscription:", error.message);
    }
  }; */

  const setOverageSubscription = async (id, subscription) => {
  
    try {
      // Make sure subscription is provided
      if (!subscription) {
        throw new Error('subscription data is required');
      }
  
      const response = await axios.put(`${process.env.REACT_APP_SET_OVERAGE_SUBSCRIPTION}/${id}`, { subscription });
  
      //console.log("setStripe response:", JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error("Error setting Stripe subscription:", error.message);
    }
  };
  
  const getCustomerDetails = async (email) => {
    //console.log("getCustomer")
    try {
      const customerResponse = await axios.get(`${process.env.REACT_APP_CUSTOMER_EMAIL}`, {
        params: {
          email: email,
        },
      });
      //console.log("customerResponse: " + JSON.stringify(customerResponse,null,2))
  
      const customer = customerResponse.data;
      // Ensure userData is defined and has an _id field
      if (userData && userData._id) {
  
        if (customer.id) {
          setCustomer(customer);
          setCustomerResponded(true);
          //console.log("stripecustomer: " + userData.stripeCustomer.customer)
          if(userData?.stripeCustomer?.customer == "not found") {
            //console.log("dsafl;kdsja")
/*             await setBaseSubscription(userData._id, {subscription: "not found"})
 */          }
          const subscriptionResponse = await axios.get(`${process.env.REACT_APP_GET_CUSTOMER_SUBSCRIPTIONS}${userData?.stripeCustomer?.id}`);
          const subscriptionData = subscriptionResponse.data;
          setSubscription(subscriptionData);
          //console.log("subscriptionData: " + subscriptionData)
/*           await setBaseSubscription(userData._id, subscriptionData)
 */          
  
          setSubscriptionResponded(true);
        }
      } else {
        console.error('User data or user ID is not defined');
      } 
    } catch (error) {
      console.error("Error in getCustomerDetails: ", error);
    }
  };
  
const updateUserSubscription = async () => {
  const update = axios.post(process.env.REACT_APP_UPDATE_USER_SUBSCRIPTION, {
    user: userData._id,
    created: subscription?.data[0]?.created,
    start: subscription?.data[0]?.current_period_start,
    end: subscription?.data[0]?.current_period_end,
    used: count,
  })
}

function readableDate(date) {
  if(date != undefined || date != null) {
    const readable = new Date(date * 1000).toLocaleString()
    return readable 
  }
  else {
    return "N/A"
  } 
}  

const handleSubmit = async (event) => {
    event.preventDefault();

    if(subscribeClicked) {
      return
    }
    setSubscribeClicked(true)
    if (!stripe || !elements) {
      return;
    }

    if (couponCode === "") {
      const confirmApplyWithoutCoupon = window.confirm("No coupon code provided. Do you want to continue?");
      if (!confirmApplyWithoutCoupon) {
          return; // Stop the submission if user cancels
      }
    }
    //console.log("baseSubscription: " + JSON.stringify(userData?.baseSubscription))
    if(!userData?.baseSubscription) {
        try {
            const subscription = await axios.post(process.env.REACT_APP_CREATE_BASE_SUBSCRIPTION, {
              email: userData?.email,
              customerId: userData?.stripeCustomer?.id,
              paymentMethodId: userData?.stripeCustomer?.invoice_settings?.default_payment_method,
              priceId: process.env.REACT_APP_SUBSCRIPTION,
              coupon: couponCode
            });
            console.log("subscription: " + JSON.stringify(subscription, null, 2))
            window.location.reload()
            // Handle success, show confirmation message to the user
          } catch (err) {
            setErrorMessage(err.message);
          }
    }     
}


const handleCancel = async () => {
  const cancelSubscription = await axios.post(`${process.env.REACT_APP_CANCEL_SUBSCRIPTION}/${userData.baseSubscription.id}`)
  window.location.reload();
  //console.log("post: " + JSON.stringify(cancelSubscription,null,2))
  if (cancelSubscription.data) {
      getCustomerDetails(userData.email)
  } 
}

function calculateAdditionalBill() {
  const periodStart = userData?.baseSubscription?.current_period_start;
  const periodEnd = userData?.baseSubscription?.current_period_end;
  console.log("periodStart: " + periodStart);
  console.log("periodEnd: " + periodStart);

  let updatedCount = count; // Initialize with current count value

  if (comps && comps.length > 0) {
    for (let i = 0; i < comps.length; i++) {
      let date = new Date(comps[i].dateUploaded);
      let utcDate = Math.floor((date.getTime() + (68800 * 1000)) / 1000);
      console.log("utcDate: " + utcDate);
      if (utcDate > periodStart && utcDate < periodEnd && comps[i].status === "Finished") {
        console.log("if: ");
        updatedCount++; // Increment count using a temporary variable
      }
    }
  }

  console.log("updatedCount: " + updatedCount); // Log updatedCount instead of count

  // Calculate amount and description based on updatedCount
  if ((updatedCount - userData?.monthly_allotment) > 0) {
    const calculatedAmount = (updatedCount - userData?.monthly_allotment) * userData?.additional_cost;
    setAmount(calculatedAmount);

    const writeDescription = `You used ${updatedCount - userData?.monthly_allotment} additional comparisons this billing cycle @ $${userData?.additional_cost} per comparison. Total: ${calculatedAmount}`;
    setDescription(writeDescription);
  }

  setCount(updatedCount); // Update count state with the final value
  setCalculateResponded(true);
}

const getResults = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_RESULTS_DATA}user/${id}`);
    const data = await response.json();

    setComps(data);
    setComparisonsResponded(true)
  } catch (error) {
    console.error("Error fetching results:", error);
  }
}
function checkUserState() {
  if (userData?.coupon != "") {
    return true
  }
  else {
    return false
  }
}

function returnForms(){
  if((userData?.baseSubscription === undefined || userData?.baseSubscription === null) && (userData?.stripeCustomer?.invoice_settings?.default_payment_method !== undefined)){
    console.log("userData?.stripeCustomer?.invoice_settings?.default_payment_method: " + userData?.stripeCustomer?.invoice_settings?.default_payment_method)
  
    return(
          <form  className="subscribe-form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Coupon Code"
                  value={couponCode}
                  disabled={checkUserState()}
                  onChange={(e) => setCouponCode(e.target.value)}
              />
              <button className="green-button" type="submit" disabled={!stripe}>
                  Subscribe
              </button>
              {errorMessage && <div>{errorMessage}</div>}
              
          </form>
      )
  }
  else if(userData?.baseSubscription?.status === "active" && userData?.baseSubscription?.cancel_at_period_end) {
    return(
      <div className="subscribe-form">
          <span>Subscription set to cancel on {new Date(userData?.baseSubscription?.cancel_at * 1000).toLocaleString()}.</span>
          
      </div>
    )
  }
  else if(userData?.baseSubscription?.status === "active")
     {
      //console.log("userData: " + JSON.stringify(userData, null, 2))
      return(
        <div className="cancel-container">
          <button className="gray-button" onClick={()=>handleCancel()} type="submit">
              Cancel Subscription
          </button>   
          
        </div>  
      )
  }
  else {
      return
  }
}

  return (
    <div>
      {returnForms()}
      <div className="price-model">
      <p>
        Comparisons used this cycle:{" "}
        <strong>
          {userData?.baseSubscription?.current_period_start ? count : "No Subscription"}
        </strong>
      </p>        
        <p>Allotment: <strong style={unsetColor}>{userData?.monthly_allotment}</strong></p>
        
        <p>Price/Additional: <strong style={unsetColor}>${userData?.additional_cost}</strong></p>

        <br />
        <br />

        <p>Subscription Created</p>
        <strong>{readableDate(userData?.baseSubscription?.created)}</strong>

        <p>Current Period Start</p>
        <strong>{readableDate(userData?.baseSubscription?.current_period_start)}</strong>

        <p>Current Period End</p>
        <strong>{readableDate(userData?.baseSubscription?.current_period_end)}</strong>
      </div>
    </div>
  );
};

export default SubscriptionForm;