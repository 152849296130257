import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { userConstants } from './actions/userTypes';
import ProtectedRoutes from './utils/protectedRoutes';
import { authenticateUserWithToken } from './actions/auth';
import LoginForm from './components/login/loginForm';
import AdminDashboard from './components/admin/adminDashboard';
import MainWrapper from './components/wrappers/mainWrapper';
import Dashboard from './components/views/dashboard';
import ActiveClaims from './components/comparison/activeComparison';
import ArchivedClaims from './components/views/archivedClaims';
import InsuranceCompanies from './components/views/insuranceCompanies';
import Statistics from './components/views/statistics';
import UploadDocs from './components/comparison/estimateUpload';
import CreateNewComparison from './components/comparison/createNewComparison';
import ComparisonResults from './components/views/comparisonResults';
import RegisterForm from './components/registration/userRegister';
import UpdateForm from './components/views/userUpdate';
import Reports from './components/views/reports';
import ComparisonReports from './components/views/comparisonReports';
import Accounts from './components/views/accounts';
import AdminPanel from './components/admin/adminPanel';
import ForgotPassword from './components/login/forgotPass';
import ResetPass from './components/login/resetPass';
import ContinueComparison from './components/views/continueComparison';
import EditAccount from './components/admin/editAccount';

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => !!state.token);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Dispatch action to authenticate user with token
      dispatch(authenticateUserWithToken(token));
    }
  }, [dispatch]);

  useEffect(() => {
    let timer;
    if (isAuthenticated) {
      timer = setTimeout(() => {
        dispatch({ type: userConstants.LOGOUT_USER });
      }, 3600000); // 60000ms = 1 minute
    }

    // Clear the timer when the component unmounts or when isAuthenticated changes
    return () => clearTimeout(timer);
  }, [isAuthenticated, dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/dashboard" element={<Navigate replace to="/activeClaims" />} />
          <Route path="/clientDashboard" element={<MainWrapper />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/activeClaims" element={<ActiveClaims />} />
          <Route path="/createNewComparison" element={<CreateNewComparison />} />
          <Route path="/archivedClaims" element={<ArchivedClaims />} />
          <Route path="/insuranceCompanies" element={<InsuranceCompanies />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/adminDashboard" element={<AdminDashboard />} />
          <Route path="/comparisonResults" element={<ComparisonResults />} />
          <Route path="comparisonReports" element={<ComparisonReports />} />
          <Route path="/comparisonResults/:comparisonID" element={<ComparisonResults />} />
          <Route path="/adminPanel" element={<AdminPanel />} />
          <Route path="/editAccount/:id" element={<EditAccount />} />
          <Route path="/continueComparison/" element={<ContinueComparison />} />
          <Route path="/continueComparison/:continueCompID" element={<ContinueComparison />} />
        </Route>

        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/update" element={<UpdateForm />} />
        <Route path="/estimates" element={<UploadDocs />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPass />} />
      </Routes>
    </Router>
  );
}

export default App;
