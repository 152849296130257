import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Logout from '../logout/logout';

import levlr_logo from "../../img/LEVLR Logo_Reveersed.png";


const LeftNavigationPanel = props => {

    const [isActive, setActive] = useState(false);
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    const userData = useSelector(state => state.user)
    // const isActive = useSelector(state => state.user.isActive)
    const isAdmin = userData.admin
    //console.log("email: " + userData.email)
    //console.log('admin?: ' + userData.admin) // returned "user"
    // returned "undefined" console.log('admin? ' + userData.role.admin)
    
    function toggleClass() {
        setActive(!isActive);
    };

    {/* style={({isActive}) => ({})} */}

    function checkAdmin(){
        if(isAdmin == true){
            return(
                <li>{<NavLink to="/adminPanel" className="nav-item nav-link">Admin</NavLink>}</li>
            )
        }
    }

    function checkInternal() {
        if(userData.email === "john.coday@pdgcreative.com" || userData.email === "caleb.williams@pdgcreative.com" || userData.email === "andrew@pdgcreative.com" || userData.email === "travis@pdgcreative.com" || userData.email === "travis+levlr.test@pdgcreative.com") {
            return(
                <li>{<NavLink to="/comparisonReports" className="nav-item nav-link">Comparison Report</NavLink>}</li>
            )
        }
    }

    function renderLeftNav() {
    
        if (isActive == false) {
            return (
                
                <div className={'left-container'}>

                    <div className={'elkmont-logo-container'}>
                        <img src={levlr_logo} className={'elkmont-logo-resize'}></img>
                    </div>
                        
                    <nav className={'nav-list'}>
                        
                        <ul>
                            {/* <li><NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'clicked-dashboard' : null)}>Dashboard</NavLink></li> */}
                            <li><NavLink to="/activeClaims" className={({ isActive }) => (isActive ? 'clicked-active-claims' : null)}>Active Comparisons</NavLink></li>
                            {/*<li><NavLink to="/archivedClaims" className={({ isActive }) => (isActive ? 'clicked-archived-claims' : null)}>Archived Comparisons</NavLink></li>
                            {/* <li><NavLink to="/insuranceCompanies" className={({ isActive }) => (isActive ? 'clicked-insurance-companies' : null)}>Insurance Companies</NavLink></li>
                            <li><NavLink to="/statistics" className={({ isActive }) => (isActive ? 'clicked-statistics' : null)}>Statistics</NavLink></li>
                            <li><NavLink to="/reports" className={({ isActive }) => (isActive ? 'clicked-reports' : null)}>Reports</NavLink></li> */}
                            {checkAdmin()}
                            {checkInternal()}
                            {/* <li><NavLink to="/adminDashboard" className="nav-item nav-link">Admin</NavLink></li> */}
                        </ul>
                        {/* <button onClick={Logout()} className="button-page">Logout</button> */}
                        
                    </nav>
                </div>
            ) 
        }
      
    }

    return (
        <div>
            <div className={isActive ? 'hamburger': 'hamburger-change'} onClick={() => {toggleClass();}}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            {renderLeftNav()}
        </div>
    )

}

export default LeftNavigationPanel