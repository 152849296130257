import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../../css/ComparisonResults.css';
import MainWrapper from '../wrappers/mainWrapper';
import Button from '@mui/material/Button';
import Multiselect from 'multiselect-react-dropdown';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'react-modal';
import axios from 'axios'
import JsPDF from 'jspdf'
import LevlrLogo from '../../img/LEVLR Logo_CMYK.png'

const ComparisonResults = props => {

let doc1ZoneState = []
const [newZonesToCompare, setNewZonesToCompare] = useState([])
var stringSimilarity = require("string-similarity");
const [responded, setResponded] = useState(false)
const [getSubzones, setGetSubzones] = useState([])
const [data, dataSet] = useState({})
const [filter, setFilter] = useState("percentage");
const [percentValue, setPercentValue] = useState(0.0);
const [currentSection, setCurrentSection] = useState("results");
const { comparisonID } = useParams();
const [markForExport, setMarkForExport] = useState([])
const [open, setOpen] = useState(false)
const [currentNoteId, setCurrentNoteId] = useState("")
const [loadingStatus, setLoadingStatus] = useState('loading-spinner')
const pdfRef = useRef(null);
const MAX = 85;
const [noteLog, setNoteLog] = useState([])
const [currentNote, setCurrentNote] = useState("")
const [markConfirm, setMarkConfirm] = useState([])

let sorted = markForExport.sort()
const generatePDF = () => {  
  const doc = new JsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a1',
    hotfixes: ["px_scaling"]
  }); 

  let emptyCopy = document.getElementById('pdfCopy')
  let newHeader = document.getElementById('newHeader')
  let newHeaderClone = newHeader.cloneNode(true)

  while (emptyCopy.firstChild) {
    emptyCopy.removeChild(emptyCopy.lastChild);
  }

  emptyCopy.append(newHeaderClone)

  if(markForExport.length > 0) {
    sorted.map((key,val) => {
      if(document.getElementById(key) === null) {
        alert(`${key} is not visible for PDF export. If you wish to include this in the PDF export, please make sure it is visible. `)
      } 
      else {
      let p = document.getElementById(key);
      let p_prime = p.cloneNode(true);
      let removeKid = p_prime.removeChild((p_prime.lastChild))
      let removeKid1 = removeKid.removeChild(removeKid.lastChild)
        return (
          emptyCopy.append(p_prime)
        )
      }
      
    })
    let fileName = prompt("Please Enter A File Name.")
    doc.html(emptyCopy, {
      callback: function(doc) {
        doc.addImage(LevlrLogo, 'PNG', 2000, 20, 203, 70);
        doc.save(`${fileName}.pdf`);
      },
    });
  }
  else {
      alert("No Selections Made")
  }
}

//styles
const tableStyle = { 
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#DDDDDD',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 12px 24px 12px',
  overflow: 'auto',
  border: '1px dotted black'
}

const modalStyle = {
  position: 'absolute',
  display: 'grid',
  overflow: 'hidden',
  backgroundColor: 'white',
  opacity: '95%',
  width: '100%',
  height: '100%',
  borderRadius: '15px',
  padding: '12px 12px',
  boxSizing: 'border-box'
}

const modalRestyle = {
  overlay: {
    width: '40%',
    height: '44%',
    top: '54%',
    left: '1%',
    overflow: 'hidden',
    borderRadius: '15px',
    border: '4px solid black',
    boxSizing: 'border-box',
    padding: '0 0',
  },

  content: {
    overflow: 'hidden',
    background: 'none',
    border: 'none',
    inset: '0px',
    padding: '0 0'
  },
}

const noteStyle = {
  border: '2px solid #526666',
  resize: 'none',
  fontSize: '20px',
  padding: '4px 4px',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box'
}

const notInAnyZone = {
  fontSize: '24px',
  textAlign: 'center',
  padding: '0px 0px',
  margin: '0px 0px'
}

const endOfDocTable = {
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#e7d4d8',
  borderRadius: '4px',
  margin: '12px 12px 12px 12px',
  padding: '12px 8px',
  overflow: 'auto',
  border: '1px dotted black',
}

const pdfCopy = {
  display: 'flex-box',
  flexDirection: 'column',
  margin: '12px 12px 12px 12px',
  padding: '16px',
  fontSize: '28px',
  width: '116%',
  overflowY: 'auto',
}

const theadStyle = {
  fontSize: '20px'
}

const newHeaderStyle = {
  fontSize: '32px',
  paddingBottom: '12px'
}

const borderTop = {
  position: 'relative',
  gridTemplateRows: 'auto auto',
  height: 'auto',
  width: '100%',
  borderRadius: '4px',
  borderTop: '2px solid black',
  marginTop: '36px'
}

const getZonePairingsContainer = {
  display: 'block',
}

const noMatchTableStyle = {
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#e7d4d8',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 12px 24px 12px',
  overflow: 'auto',
  border: '1px dotted black'
}

const lastRow = {
  display: 'flex',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto',
  width: '100%',
  alignItems: 'center',
  padding: '4px 4px'
}

const mapStyle = {
  position: 'relative',
  height: 'auto',
  display: 'grid',
  gridTemplateRows: 'auto auto',
  marginTop: '6px',
}

const verticalLabel = {
  padding: '16px 4px',
  margin: '4px 4px',
  textAlign: 'center',
  border: 'none',
  fontStyle: 'italic',
  opacity: '50%',
  textDecoration: 'underline'
}

const labelStyle = {
  margin: '12px 0px',
  color: '#00B3B5',
  textAlign: 'left'
}

const doc2ZoneStyle = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#00B3B5', 
  textDecoration: 'underline',
  fontSize: '24px'
}

const addNoteButton = {
  backgroundColor: '#00B3B5',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  margin: '0px 8px',
  display: 'inline',
  width: '20%'
}

const updateNoteButton = {
  backgroundColor: '#723E49',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  margin: '0px 8px',
  display: 'inline',
  width: '20%'
}

const dropdownStyle = {
  textAlign: 'center',
  margin: '2px 6px',
  fontWeight: 'bold',
  fontSize: '1em',
  color: '#526666',
  border: 'none',
  padding: '1px 8px',
  borderRadius: '4px',
  backgroundColor: '#DDDDDD'
}

const zoneStyle = {
  margin: '12px 0px',
  fontWeight: 'bold',
  fontSize: '1em',
  padding: '2px 12px',
  color: '#526666'
}

const thStyle = {
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: '#526666',
  border: 'none',
  textAlign: 'left',
  alignSelf: 'center'
}

const trStyle = {
  width: '100%',
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'space-between'
}

const trStyleFlexStart = {
  width: '100%',
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'flex-start'
}

const tbodyStyle = {
  overflow: 'auto',
  display: 'inline'
}

const tbodyNote = {
  overflow: 'auto',
  display: 'inline',
  marginTop: '24px',
  inlineSize: '80%',
  wordBreak: 'break-all',
  fontWeight: 'bold',
  fontSize: '18px',
}

const tdPropStyle = {
  display: 'block',
  margin: '0px 0px',
  color: '#00B3B5',
  textAlign: 'left',
}

const tdValueStyle = {
  color: '#526666',
}

const filterLabel = {
  margin: '2px 8px 2px 16px',
  padding: '8px 0px',
  color: '#00B3B5'
}

const containsPropVal = {
  display: 'block',
  width: '200px',
  overflow: 'auto',
  padding: '0 4px',
  margin: '12px 0px 0px 0px'
}

useEffect(() => {
  console.log("data: " + JSON.stringify(data,null,2))
    getSubzonesData()
    getNotesData()
    getConfirmData()
    if (!responded) {
      getResultsData()
    }
    else if (responded === true && getSubzones.length > 0){
      setNewZonesToCompare(getSubzones[0].stateObject)
      setLoadingStatus('')
    }
    else if (responded === true) {
      setNewZonesToCompare(
        doc1ZoneState.map((val, index) => {
          return({
            Id: index,
            Doc1: val,
            SubZones: []
          })
        }),
      )
      setLoadingStatus('')
    }
    renderZones()
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
  
    if(noteLog.length > 0) {
      window.addEventListener('beforeunload', handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
}, [responded, loadingStatus])


const toggleResults = () => {
  if(currentSection == "results"){
    setCurrentSection("full_results")
  }else{
    //filtered set of results
    setCurrentSection("results")
  }
}

const getResultsData = async () => {
  const comp = await fetch(`${process.env.REACT_APP_RESULTS_DATA}comp/${comparisonID}`, {timeout: 8000}).then((response) => response.json({}))
  if (comp.hasOwnProperty("full_resultsID") || comp.hasOwnProperty("results_ID")) {
    if(comp["full_resultsID"] === "NaN") {
      const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
      dataSet({
        results: JSON.parse(split.data), 
        full_results: JSON.parse(JSON.stringify(split.data)), 
        claimData: comp.claimData, 
        files: comp.files, 
        headers: comp.headers, 
        resultsID: comp.resultsID, 
        full_resultsID: comp.full_resultsID,
        dateUploaded: comp.dateUploaded,
        statusMSG: comp.statusMSG,
        status: comp.status,
        doc1_name: comp.doc1_name,
        doc2_name: comp.doc2_name,
        headersOne: comp.headersOne,
        headersTwo: comp.headersTwo,
        no_match_a: comp.no_match_a,
        no_match_b: comp.no_match_b
      })
      setResponded(true)
    }
    else if(comp["full_resultsID"] != "NaN") {
      const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
      const fullSplit = await fetch(`${process.env.REACT_APP_SPLIT_FULL_RESULTS}/${comp.full_resultsID}`).then((response) => response.json({}))
      dataSet({
        results: JSON.parse(split.data), 
        full_results: JSON.parse(JSON.stringify(fullSplit.data)), 
        claimData: comp.claimData, 
        files: comp.files, 
        headers: comp.headers, 
        resultsID: comp.resultsID, 
        full_resultsID: comp.full_resultsID,
        dateUploaded: comp.dateUploaded,
        statusMSG: comp.statusMSG,
        status: comp.status,
        doc1_name: comp.doc1_name,
        doc2_name: comp.doc2_name,
        headersOne: comp.headersOne,
        headersTwo: comp.headersTwo,
        no_match_a: comp.no_match_a,
        no_match_b: comp.no_match_b
      })
      setResponded(true)
    }
  }
  else {
    dataSet(comp)
    setResponded(true)
  }
}

//Function to collect subzone record
const getSubzonesData = async () => {
  const response = await fetch(
  `${process.env.REACT_APP_GET_SUBZONES}${comparisonID}`, {timeout: 8000}
  ).then((response) => response.json({}));
  setGetSubzones(response)
};

//Function to collect subzone record
const getNotesData = async () => {
  const response = await fetch(
  `${process.env.REACT_APP_GET_NOTES}${comparisonID}`, {timeout: 8000}
  ).then((response) => response.json({}));
  if(Object.keys(response).length > 0) {
    setNoteLog(response[0]["stateObject"])
  }
};

const getConfirmData = async () => {
  const response = await fetch(
  `${process.env.REACT_APP_GET_CONFIRM}${comparisonID}`, {timeout: 8000}
  ).then((response) => response.json({}));
  if(Object.keys(response).length > 0) {
    setMarkConfirm(response[0]["stateObject"])
  }
};

//Function to put subzones into database
//id param is equal to comparisonID
function handleSaveZones(id) {
  axios.put(`${process.env.REACT_APP_SAVE_ZONES}${id}`, newZonesToCompare)
    .then(response => {   
      alert("Zones Saved To Database!");
      console.log("Zones Posted.");
    })
    .catch(error => {
      alert("Saving was unsuccessful. Please try again.");
      console.error('There was an error!', error);
    });
}

function handleSaveNotes(id) {
  axios.put(`${process.env.REACT_APP_SAVE_NOTES}${id}`, noteLog)
  .then(response => {   
    alert("Notes Saved To Database!");
    console.log("Notes Posted.");
  })
  .catch(error => {
    alert("Saving was unsuccessful. Please try again.");
    console.error('There was an error!', error);
  });
}

function handleSaveConfirm(id) {
  axios.put(`${process.env.REACT_APP_SAVE_CONFIRM}${id}`, markConfirm)
  .then(response => {   
    alert("No-Matches Saved To Database!");
    console.log("No-Matches Posted.");
  })
  .catch(error => {
    alert("Saving was unsuccessful. Please try again.");
    console.error('There was an error!', error);
  });
}

function saveChanges (id) {
  handleSaveNotes(id)
  handleSaveZones(id)
  handleSaveConfirm(id)
}

const openNote = (e, id) => {
  setOpen(true)
  setCurrentNoteId(id)
}

const updateNote = (e, id) => {
  setOpen(true)
  setCurrentNoteId(id)
}

function findPlaceholderText(id) {
  if(noteLog.find((element) => {
    return (element.id === id);
  })){
    return (
      noteLog.find((element) => {
        return (element.id === id);
      })
    ).note
  }
  else return
  
}

function renderAddOrUpdate(currentNoteId) {
  if(findPlaceholderText(currentNoteId) === undefined) {
      return false
    }
  else {
    if(findPlaceholderText(currentNoteId).length > 0){
      return true
    }
  }
}

function renderNote() {
  return(
    <Modal
      style={modalRestyle}
      isOpen={open}
      onRequestClose={() => handleNoteClose()}
      preventScroll={false}
      ariaHideApp={false}
    >
      <div style={modalStyle}>
        <h3 style={{color: '#526666'}}>{renderAddOrUpdate(currentNoteId)? "Please Update Your Note.":"Please Add A Note."}</h3>
        <textarea
          style={noteStyle}
          id={currentNoteId}
          name={currentNoteId} 
          defaultValue={findPlaceholderText(currentNoteId)}
          rows="8"
          cols="50"
          onBlur={handleNoteChange}
        >
        </textarea>

        <Button
          size="small"
          sx={{
            ':hover': {
                backgroundColor: '#999999'
            },
          height: '50px',
          margin: '16px 0px 0px 0px', 
          fontWeight: 'bold', 
          color: 'white', 
          backgroundColor: '#00B3B5'}}
          onClick={handleNoteSave}
        >
          {renderAddOrUpdate(currentNoteId)? "UPDATE NOTE": "ADD NOTE"}
        </Button>

        <Button
          size="small"
          sx={{
            ':hover': {
                backgroundColor: '#999999'
            },
          margin: '0px 0px 8px 0px', 
          fontWeight: 'bold', 
          height: '50px',
          color: 'white', 
          backgroundColor: '#723E49'}}
          onClick={handleNoteClose}
        >
          Exit
        </Button>
      </div>      
    </Modal>
  )
  
}

function handleNoteClose() {
  setOpen(false)
}

const handleNoteChange = (e) => {
  setCurrentNote(e.target.value)
}

const handleNoteSave = (e) => {  
  let objIndex = noteLog.find(obj => obj.id === currentNoteId) 
  let newNote = {
    id: currentNoteId,
    note: currentNote
  }
  if (objIndex === undefined) {
    setNoteLog([...noteLog, newNote])
    setOpen(false)
  }
  else {
    objIndex.note = currentNote
    setOpen(false)
  }
  
}


function checkDisabledNote (id) {
  if ((noteLog.find( note => note['id'] === id)) === undefined) {
    return false
  }
  else {
    return true
  }
}

function renderClaimCredentials() {
  if(data["claimData"] != null || data["claimData"] != undefined){
    return(
      <div style={newHeaderStyle} id={'newHeader'}>
        <p>Adjuster : {data["claimData"]["adjuster"]}</p>
        <p>Insured : {data["claimData"]["insured"]}</p>
        <p>Insured Address : {data["claimData"]["insured_address"]}</p>
        <p>Claim Number : {data["claimData"]["claim_number"]}</p>
      </div>
    )
  }
  else {
    return
  }
}

function renderAddNote(id) {
  if (!checkDisabledNote(id)) {
    return (
      <Button
        id={id}
        style={addNoteButton}
        onClick={(e) => openNote(e, id)}
      >
        Add Note
      </Button>
  )  
  }
  else if (checkDisabledNote(id)) {
    return (
      <Button
        id={id}
        style={updateNoteButton}
        onClick={(e) => updateNote(e, id)}
      >
        Update Note
      </Button>
  )  
  }
}

function renderProperNote(id) {
  return(
    noteLog.map((key, val) => { 
        if(key.id === id) {
          return(
            <tr style={tbodyNote}>
                Note: {key.note}
            </tr>
          )
        }
      }
    )
  )
}

function noMatchesEndOfResultsA(lineItemZero) {
  if(data["no_match_a"] != null && data["no_match_b"] != null){
    let a = JSON.parse(data["no_match_a"])
    let b = JSON.parse(data["no_match_b"])
    
    if(Object.keys(a).length > 0) {
      return(
        (
          Object.entries(a).map(([keys, vals]) => {
            return(
              <table 
                style={endOfDocTable}
                id={'zzz' + keys}
              >
                <thead style={notInAnyZone}>
                  <tr>
                    <th>NOT FOUND IN ANY ZONE</th>
                  </tr>
                </thead>
              <tbody id={"string"} key={keys} style={tbodyStyle}>
              <tr style={trStyle}>
              {Object.entries(vals).slice(0, 1).map(([key, val]) => (
                <th style={thStyle} key={key}>
                  {key}: {val}</th>
              ))}
              </tr>

              <tr style={trStyle}>
              <th style={verticalLabel}>Doc1</th>

                {Object.entries(vals).slice(1).map(([key, val]) => (
                  <td id={key} key={key} style={containsPropVal}>
                    <td style={tdPropStyle}>
                      {key}
                    </td>
                    <td style={tdValueStyle}>
                      {val}
                    </td>
                  </td>
                ))}
              
              </tr>
            </tbody> 
            <tbody>
              {renderProperNote('zzz' + keys)}
            </tbody>
            <tbody style={tbodyStyle}>
              <tr style={lastRow}>

                {renderAddNote('zzz' + keys)}
                <div>
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={handleStayCheckedMatched('zzz' + keys) ? "Confirmed No-Match" : "Potential No-Match"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayCheckedMatched('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleCheckedMatched(e,'zzz' + keys)}
                  />}
                />
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={"Mark For Export"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayChecked('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleChecked(e, 'zzz' + keys)}
                  />}
                />
                </div>
            
              </tr>
            </tbody>
            </table>
            )
            
          })) 

      )
    }
  }
}

function noMatchesEndOfResultsB(lineItemZero) {
  if(data["no_match_a"] != null && data["no_match_b"] != null){
    let a = JSON.parse(data["no_match_a"])
    let b = JSON.parse(data["no_match_b"])
    
    if(Object.keys(b).length > 0) {
      return(
        (
          Object.entries(b).map(([keys, vals]) => {
            return(
              <table 
                style={endOfDocTable}
                id={'zzz' + keys}
              >
                <thead style={notInAnyZone}>
                  <tr>
                    <th>NOT FOUND IN ANY ZONE</th>
                  </tr>
                </thead>
              <tbody id={"string"} key={keys} style={tbodyStyle}>
              <tr style={trStyle}>
              {Object.entries(vals).slice(0, 1).map(([key, val]) => (
                <th style={thStyle} key={key}>
                  {key}: {val}</th>
              ))}
              </tr>

              <tr style={trStyle}>
              <th style={verticalLabel}>Doc2</th>

                {Object.entries(vals).slice(1).map(([key, val]) => (
                  <td id={key} key={key} style={containsPropVal}>
                    <td style={tdPropStyle}>
                      {key}
                    </td>
                    <td style={tdValueStyle}>
                      {val}
                    </td>
                  </td>
                ))}
              
              </tr>
            </tbody> 
            <tbody>
              {renderProperNote('zzz' + keys)}
            </tbody>
            <tbody style={tbodyStyle}>
              <tr style={lastRow}>

                {renderAddNote('zzz' + keys)}
                <div>
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={handleStayCheckedMatched('zzz' + keys) ? "Confirmed No-Match" : "Potential No-Match"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayCheckedMatched('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleCheckedMatched(e,'zzz' + keys)}
                  />}
                />
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={"Mark For Export"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayChecked('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleChecked(e, 'zzz' + keys)}
                  />}
                />
                </div>
              </tr>
            </tbody>
            </table>
            )
            
          })) 

      )
    }
  }
}
  
const getBackgroundSize = () => {
	return {
		backgroundSize: `${(percentValue * 100) / MAX}% 100%`,
	};
}

function validateRCV (val) {
  if (val === null) {
    return(   
      <td className="differentials grey" key={val}>RCV - Total: <strong>N/A</strong></td>
    )  
  }
  else if (parseInt(val) < 0) {
    return(   
      <td className="differentials grey" key={val}>RCV - Total: <strong className="green">{(val.toFixed(2)) * (-1)}</strong></td>
    )
  }
  else if (parseInt(val) > 0){
    return(   
      <td className="differentials grey" key={val}>RCV - Total: <strong className="red">{val.toFixed(2) * (-1)}</strong></td>
    )
  }
}

function validateACV (val) {
  if (val == null) {
    return(   
      <td className="differentials grey" key={val}>ACV - Total: <strong>N/A</strong></td>
    )
  }
  else if (Math.sign(val) === -1) {
    return(   
      <td className="differentials grey" key={val}>ACV - Total: <strong className="green">{val.toFixed(2) * (-1)}</strong></td>
    )
  }
  else {
    return(   
      <td className="differentials grey" key={val}>ACV - Total: <strong className="red">{(val.toFixed(2))  * (-1)}</strong></td>
    )
  }
}

function validateTotal (key, val) {  
  const parsedVal = parseInt(val)
  if (key != "Description Match Percent"){
    if (val == null) {
      return(   
        <td className="differentials grey" key={val}>Total - Total: <strong>N/A</strong></td>
      )
    }
    else if (Math.sign(val) === -1) {
      return(   
        <td className="differentials grey" key={val}>Total - Total: <strong className="red">{val.toFixed(2)}</strong></td>
      )
    }
    else {
      return(   
        <td className="differentials grey" key={val}>Total - Total: <strong className="green">{val.toFixed(2)}</strong></td>
      )
    }
  }
  else {
    return(   
      <td className="differentials grey" key={parsedVal}>Total - Total: <strong>N/A</strong></td>
    ) 
  }

}

const handleFilterChange = event => {
  setFilter(event.target.value)
}

if(data["claimData"] != null){
  let resultsLayer = data[currentSection]
  let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0,1).toString())
  let noZone1Duplicates = [...new Set(resLayer1)]
  let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
  let noZone2Duplicates = [...new Set(resLayer2)]
  doc1ZoneState.push(...noZone1Duplicates)
}

const handleSelect = (zone1Name, e) => {
  setNewZonesToCompare(current => 
    current.map(obj => {
      if (obj.Doc1 === zone1Name) {
        return {...obj, SubZones: [e]};
      }
      return obj;
    })
  )
}

const handleChecked = (event, lineItemZero) => {
    if(markForExport.includes(lineItemZero)) {
      setMarkForExport(oldValues => {
        return oldValues.filter(id => id !== lineItemZero)
      })
    }
    else {
      setMarkForExport([...markForExport, lineItemZero])
    }
}

const handleCheckedMatched = (event, lineItemZero) => {
    if(markConfirm.includes(lineItemZero)) {
      setMarkConfirm(oldValues => {
        return oldValues.filter(id => id !== lineItemZero)
      })
      setMarkForExport(oldValues => {
        return oldValues.filter(id => id !== lineItemZero)
      })
    }
    else {
      setMarkConfirm([...markConfirm, lineItemZero])
      setMarkForExport([...markForExport, lineItemZero])
    }
}

const handleStayChecked = (lineItemZero) => {
  if (markForExport.includes(lineItemZero)) {
    return true
  }
  else {
    return false
  }
}

const handleStayCheckedMatched = (lineItemZero) => {
  if (markConfirm.includes(lineItemZero)) {
    return true
  }
  else {
    return false
  }
}

function returnDoc2Zones(val) {
  return val
}

function returnSubZonesArray(zone1Name, index) {
  if (newZonesToCompare[index]["SubZones"].length === 0) {
    return bestMatch(zone1Name)
  }
  else {
    return (
      newZonesToCompare[index]["SubZones"][0].map((val, i) => {
        return (val.name + "\t")
      })
    )
  }
}

function findDocNumber(key1) {
  if(key1 % 2 === 0) {
    return true
  }
  else {
    return false
  }
}

function renderZones() {

  if(data["claimData"] && data["results"]){ 
    if(((Object.keys(data.full_results)).length === 0) || ((Object.keys(data.results)).length === 0)) {
      return (
        <div>
          <p className={'loading-comparisons-text'}> Insufficient Results to Display... </p>
          <p> Data may not have been parsed properly from one of the two documents. </p>
        </div>
      ) 
    }  

    let resultsLayer = data[currentSection]
    let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0,1).toString())
    let noZone1Duplicates = [...new Set(resLayer1)]
    let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
    let noZone2Duplicates = [...new Set(resLayer2)]
    doc1ZoneState.push(...noZone1Duplicates)
    let dropdown = noZone2Duplicates.map((zone2Name, index) =>
      {return({
        name: zone2Name,
        id: index
      })}
      
    )

    if(newZonesToCompare.length > 0) {
      return(
          <div style={getZonePairingsContainer}>
            {noZone1Duplicates.map((zone1Name, index) =>
            <div style={mapStyle} key={index}>

              <div>
                <label style={labelStyle}>Showing Zone From Document 1: </label>
                <label style={zoneStyle}>{zone1Name}</label>
              </div>
              <div>
                <label style={labelStyle}>Showing Zone From Document 2: </label>
                <label style={zoneStyle}>{returnSubZonesArray(zone1Name, index)}</label>
                <Multiselect 
                options={dropdown}
                key={zone1Name} 
                id={zone1Name}  
                className={'dropdown-style'}
                onSelect={(e) => handleSelect(zone1Name, e)} 
                onRemove={(e) => handleSelect(zone1Name, e)}
                hidePlaceholder = {true}
                placeholder = {" Change Zone(s) "}
                isMulti
                displayValue="name"
                />
              </div>   
              <div>
                {Object.entries(resultsLayer).map(([zero, zones]) => {
                      if (
                        (newZonesToCompare[index]["SubZones"].length === 0) &&
                        (Object.values(zones).slice(0,1).includes(zone1Name)) &&
                        (Object.values(zones).slice(1,2).includes(bestMatch(zone1Name).toString()))
                        ) {
                        return(
                          <div>
                            {Object.entries(zones).slice(2,3).map(([lineItems, lineItemsObject]) =>
                            <div ref={pdfRef} key={lineItems} id={lineItems}>
                              <label style={doc2ZoneStyle}>{returnDoc2Zones(zones["Zone 2"])}</label>
                              {Object.entries(lineItemsObject).filter(object => object[1]["Percentage"] >= percentValue).sort(function (a, b)  {
                                  return (b[1]["Percentage"] - a[1]["Percentage"])
                                }).map(([lineItemZero, lineItemZeroObject], index) =>
                                <table style={tableStyle} id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero} key={lineItemZero}>
                                  <thead>
                                    
                                    <th style={theadStyle}>
                                     {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                      &nbsp; ({index + 1}&nbsp;of&nbsp;{Object.entries(lineItemsObject).length})
                                    </th>
                                    
                                  </thead>
                                  {Object.entries(lineItemZeroObject).slice(0, 1).map(([lineItem1, lineItem1PropsVals]) =>
                                    <tbody style={tbodyStyle} key={lineItem1}>

                                      {Object.entries(lineItem1PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                        <tr style={trStyle} key={desc}>
                                          <th style={thStyle} key={descVal}>{desc}: {descVal}</th>
                                        </tr>
                                      )}
                                      <tr key={lineItem1} style={trStyle}>
                                      <th style={verticalLabel}>Doc1</th>
                                      {Object.entries(lineItem1PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                        <td style={containsPropVal}>
                                          <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                          <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                        </td>
                                      )}
                                      </tr>
                                    </tbody>
                                  )}

                                  {Object.entries(lineItemZeroObject).slice(1, 2).map(([lineItem2, lineItem2PropsVals]) =>
                                    <tbody style={tbodyStyle} key={lineItem2}>
                                      {Object.entries(lineItem2PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                        <tr style={trStyle} key={desc}>
                                          <th style={thStyle} key={descVal}>{desc}: {descVal}</th>
                                          
                                        </tr>
                                      )}
                                      <tr style={trStyle}>
                                      <th style={verticalLabel}>Doc2</th>
                                      {Object.entries(lineItem2PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                        <td style={containsPropVal}>
                                          <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                          <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                        </td>
                                      )}
                                      </tr> 
                                    </tbody>
                                  )}

                                  <tbody style={tbodyStyle}>
                                    <tr style={trStyleFlexStart}>
                                      {Object.values(lineItemZeroObject).slice(2, 3).map((val, index) =>  
                                        <td key={val}><strong></strong></td>
                                      )}
                                      {Object.values(lineItemZeroObject).slice(3, 4).map((val, index) =>                                       
                                          validateRCV(val)
                                      )}
                                      {Object.values(lineItemZeroObject).slice(4, 5).map((val, index) =>  
                                          validateACV(val)
                                      )}    
                                      {Object.entries(lineItemZeroObject).slice(5, 6).map(([key, val]) =>  
                                          validateTotal(key, val)
                                      )}   
                                    </tr>
                                  </tbody>

                                  <tbody>
                                    {renderProperNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " +  lineItemZero)}
                                  </tbody>

                                  <tbody style={tbodyStyle}>
                                    
                                    <tr style={lastRow}>
                                      
                                      {renderAddNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                      
                                      <FormControlLabel
                                        style={{
                                          fontStyle: 'italic',
                                          color: '#526666',
                                          padding: '2px 8px',
                                          display: 'inline',
                                          margin: '2px',
                                        }}
                                        label="Mark For Export"
                                        control={<Checkbox
                                          size="small"
                                          checked={handleStayChecked(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " +lineItemZero)}
                                          id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero}
                                          onChange={(e) => handleChecked(e, zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                        />}
                                      />
                                    
                                    </tr>
                                  </tbody>
                                  
                                </table>
                              )}
                            </div>
                            )}
                            {Object.entries(zones).slice(3,4).map(([lineItems, lineItemsObject]) =>
                            
                            <div>
                              {Object.entries(lineItemsObject).map(([key1, val1]) => {
                                return(
                                <table key={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} style={noMatchTableStyle}>
                                  <thead>
                                    
                                    <th style={theadStyle}>
                                      {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed":"Potential"} No-Match {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                    </th>
                                    
                                  </thead>
                                  <tbody style={tbodyStyle} key={key1}>

                                    {Object.entries(val1).slice(0,1).map(([key2, val2]) => {
                                    return(
                                    <tr style={trStyle}>
                                      <th style={thStyle}>{key2}: {val2}</th>
                                    </tr>
                                    )
                                    
                                    })}
                                    <tr key={key1} style={trStyle}>
                                    <th style={verticalLabel}>{findDocNumber(key1) ? "Doc1": "Doc2"}</th>
                                    {Object.entries(val1).slice(1).map(([key2, val2]) => {
                                      return(
                                        <td style={containsPropVal}>
                                          <td style={tdPropStyle} key={key2}>{key2}</td>  
                                          <td style={tdValueStyle} key={val2}>{val2}</td>
                                        </td>
                                      )    
                                    })}
                                      </tr>
                                    </tbody>
                                    <tbody>
                                      {renderProperNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                    </tbody>
                                     <tbody style={tbodyStyle}>
                                      <tr style={lastRow}>
                                      
                                      
                                        {renderAddNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                        <div>
                                        
                                        <FormControlLabel
                                          style={{
                                            fontStyle: 'italic',
                                            color: '#526666',
                                            padding: '2px 8px',
                                            display: 'inline',
                                            margin: '2px',
                                            width: '20%'
                                          }}
                                          label={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed No-Match" : "Potential No-Match"}
                                          control={<Checkbox
                                            size="small"
                                            checked={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                            id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                            onChange={(e) => handleCheckedMatched(e,"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          />}
                                        />
                                        <FormControlLabel
                                          style={{
                                            fontStyle: 'italic',
                                            color: '#526666',
                                            padding: '2px 8px',
                                            display: 'inline',
                                            margin: '2px',
                                            width: '20%'
                                          }}
                                          label={"Mark For Export"}
                                          control={<Checkbox
                                            size="small"
                                            
                                          checked={handleStayChecked("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                          onChange={(e) => handleChecked(e, "zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          />}
                                        />
                                        </div>
                                      
                                      </tr>
                                    </tbody>
                                </table>
                                )
                                
                              })}
                              
                            </div>
                            )}
                          </div>
                          )
                      }
                      else if ((newZonesToCompare[index]["SubZones"].length > 0)) {
                        let subzones = (newZonesToCompare[index]["SubZones"]).map((obj, index) => {   
                          return(
                            obj.map((obj2, index) => {
                              return obj2.name
                            })
                          )
                        })
                        for(let i = 0; i < subzones[0].length; i++) {
                          if(
                            (Object.values(zones).slice(0,1).includes(zone1Name)) &&
                            (Object.values(zones).slice(1,2).includes(subzones[0][i]))
                          ){
                            return (
                              <div>
                                {(
                                Object.entries(zones).slice(2,3).map(([lineItems, lineItemsObject]) =>
                                  <div key={lineItems} id={lineItems}>
                                    <label style={doc2ZoneStyle}>{returnDoc2Zones(zones["Zone 2"])}</label>
                                    {Object.entries(lineItemsObject).filter(object => object[1]["Percentage"] >= percentValue).sort(function (a, b)  {
                                      return(b[1]["Percentage"] - a[1]["Percentage"])            
                                      }).map(([lineItemZero, lineItemZeroObject], index) =>
                                      <table style={tableStyle} id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero} key={lineItemZero}>
                                        <thead>
                                          <th style={theadStyle}>
                                          {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                          &nbsp; ({index + 1}&nbsp;of&nbsp;{Object.entries(lineItemsObject).length})

                                          </th>
                                        </thead>
                                        {Object.entries(lineItemZeroObject).slice(0, 1).map(([lineItem1, lineItem1PropsVals]) =>
                                          <tbody style={tbodyStyle} key={lineItem1}>
                                            {Object.entries(lineItem1PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                              <tr style={trStyle} key={desc}>
                                                <th style={thStyle} key={descVal}>{desc}: {descVal}</th>
                                              </tr>
                                            )}
                                            <tr key={lineItem1} style={trStyle}>
                                            <th style={verticalLabel}>Doc1</th>
                                            {Object.entries(lineItem1PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                              <td style={containsPropVal}>
                                                <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                                <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                              </td>
                                            )}
                                            </tr>
                                          </tbody>
                                        )}
                                        {Object.entries(lineItemZeroObject).slice(1, 2).map(([lineItem2, lineItem2PropsVals]) =>
                                          <tbody style={tbodyStyle} key={lineItem2}>
                                            {Object.entries(lineItem2PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                              <tr style={trStyle} key={desc}>
                                                <th style={thStyle} key={descVal}>{desc}: {descVal}</th>
                                              </tr>
                                            )}
                                            <tr style={trStyle}>
                                            <th style={verticalLabel}>Doc2</th>

                                            {Object.entries(lineItem2PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                              <td style={containsPropVal}>
                                                <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                                <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                              </td>
                                            )}
                                            </tr>
                                          </tbody>
                                        )}
                                        <tbody style={tbodyStyle}>
                                          <tr style={trStyleFlexStart}>
                                            {Object.values(lineItemZeroObject).slice(2, 3).map((val, index) =>  
                                              <td key={val}> <strong></strong></td>
                                            )}
                                            {Object.values(lineItemZeroObject).slice(3, 4).map((val, index) =>                                       
                                                validateRCV(val)
                                            )}
                                            {Object.values(lineItemZeroObject).slice(4, 5).map((val, index) =>  
                                                validateACV(val)
                                            )}
                                            {Object.entries(lineItemZeroObject).slice(5, 6).map(([key, val]) =>  
                                                validateTotal(key, val)
                                            )} 
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          {renderProperNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                        </tbody>
                                        <tbody style={tbodyStyle}>
                                          <tr style={lastRow}>
                                            
                                            {renderAddNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                            <FormControlLabel
                                              style={{
                                                fontStyle: 'italic',
                                                color: '#526666',
                                                padding: '2px 8px',
                                                margin: '2px',
                                                display: 'inline',
                                              }}
                                              label="Mark For Export"
                                              control={<Checkbox
                                                size="small"
                                                checked={handleStayChecked(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                                id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero}
                                                onChange={(e) => handleChecked(e, zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                              />}
                                            />
                                          </tr>
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                )
                            )}
                                {
                                  (
                                    Object.entries(zones).slice(3,4).map(([lineItems, lineItemsObject]) =>
                                      <div>
                                {Object.entries(lineItemsObject).map(([key1, val1]) => {
                                  return(
                                  <table key={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} style={noMatchTableStyle}>
                                    <thead>
                                      
                                      <th style={theadStyle}>
                                        {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed":"Potential"} No-Match {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                      </th>
                                      
                                    </thead>
                                    <tbody style={tbodyStyle} key={key1}>

                                      {Object.entries(val1).slice(0,1).map(([key2, val2]) => {
                                      return(
                                      <tr style={trStyle}>
                                        <th style={thStyle}>{key2}: {val2}</th>
                                      </tr>
                                      )
                                      
                                      })}
                                      <tr key={key1} style={trStyle}>
                                      <th style={verticalLabel}>{findDocNumber(key1) ? "Doc1": "Doc2"}</th>
                                      {Object.entries(val1).slice(1).map(([key2, val2]) => {
                                        return(
                                          <td style={containsPropVal}>
                                            <td style={tdPropStyle} key={key2}>{key2}</td>  
                                            <td style={tdValueStyle} key={val2}>{val2}</td>
                                          </td>
                                        )    
                                      })}
                                        </tr>
                                      </tbody>
                                      <tbody>
                                        {renderProperNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                      </tbody>
                                      <tbody style={tbodyStyle}>
                                        <tr style={lastRow}>
                                        
                                        
                                          {renderAddNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          <div>
                                            
                                          <FormControlLabel
                                                style={{
                                                  fontStyle: 'italic',
                                                  color: '#526666',
                                                  padding: '2px 8px',
                                                  display: 'inline',
                                                  margin: '2px',
                                                  width: '20%'
                                                }}
                                                label={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed No-Match" : "Potential No-Match"}
                                                control={<Checkbox
                                                  size="small"
                                                  checked={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                  id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                                  onChange={(e) => handleCheckedMatched(e,"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                />}
                                              />
                                              <FormControlLabel
                                                style={{
                                                  fontStyle: 'italic',
                                                  color: '#526666',
                                                  padding: '2px 8px',
                                                  display: 'inline',
                                                  margin: '2px',
                                                  width: '20%'
                                                }}
                                                label={"Mark For Export"}
                                                control={<Checkbox
                                                  size="small"
                                                  
                                                checked={handleStayChecked("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                                onChange={(e) => handleChecked(e, "zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                />}
                                              />
                                  
                                          </div>
                                        
                                        </tr>
                                      </tbody>
                                  </table>
                                  )
                                  
                                })}
                                
                              </div>
                                      )
                                  )
                                  }
                                </div>
                              )
                            }
                          }
                        }
                    }
                  )} 
              </div> 
              
            </div>
            
            )}

            <div style={borderTop}>
            {noMatchesEndOfResultsA('zzz')}
            {noMatchesEndOfResultsB('zzz')}
            </div>
          </div>
      )
    }
  }
  
}

function bestMatch(zone1Name) {
  if(data["claimData"] != null){
    let resultsLayer = data[currentSection]
    let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0,1).toString())
    let noZone1Duplicates = [...new Set(resLayer1)]
    let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
    let noZone2Duplicates = [...new Set(resLayer2)]
      return(
        Object.entries(stringSimilarity.findBestMatch(zone1Name, noZone2Duplicates)).slice(1,2).map(([key, val]) => 
          Object.entries(val).slice(0,1).map(([key2, val2]) => 
            {return(
              val2 
            )}
    )))
  }
}

  return (
    <div className={'outer-container'}>
      <MainWrapper/>
      <div className={'right-container'}>
        <div className={'comparison-results-container'}>
        <h1 className={'h1-comparison-results'}>Comparison Results</h1>
          <div className={'comparison-results-actions-div'}>
            <Button
            size="small"
            sx={{
              ':hover': {
                  backgroundColor: '#999999'
              },
            margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5'}}
            onClick={generatePDF}>
              Export PDF
            </Button>
            
            <Button 
              size="small"
              sx={{
                pointerEvents: 'none',
                ':hover': {
                    backgroundColor: '#999999'
                },
              margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5'}}
              onClick={toggleResults}
              >
                Toggle Results
            </Button>

            <Button 
              size="small"
              sx={{
                ':hover': {
                    backgroundColor: '#999999'
                },
              margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5'}}
             
              onClick={() => saveChanges(comparisonID)}
            >
              Save Changes
            </Button>

            <div>
              <input
                id="filter-percentage"
                type="range"
                min="0"
                max={MAX}
                onChange={(e) => setPercentValue(e.target.value)}
                style={getBackgroundSize()}
                value={percentValue}
              />
              <label className={'filter-label'} htmlFor="filter-percentage">LEVLR Match Assistance</label>
            </div>
          </div>
            
          {/*render mockup with zones*/}
          <div id={'pairings'} className={'pairings-container'}>
            <div className={loadingStatus}></div>
            {renderZones()}
            {renderNote()}

          </div>
        </div>

      </div>

      <div style={pdfCopy} id={'pdfCopy'}>
      
      </div>
      {renderClaimCredentials()}
    </div>
  )
}
export default ComparisonResults