import { findByLabelText } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Logout from '../logout/logout';
import '../../css/AdminDashboard.css';
import caretDown from '../../img/caret-down.svg'
import caretUp from '../../img/caret-up.svg'

import { activateUser, deactivateUser } from '../../actions/auth';

import MainWrapper from '../wrappers/mainWrapper';

const AdminDashboard = props => {
   
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    const userData = useSelector(state => state.user)
    const [filter, setFilter] = useState(true)
    const [activeUsers, setActiveUsers] = useState()
    const [inactiveUsers, setInactiveUsers] = useState()
    const [currEmail, setCurrEmail ] = useState({
        email: ''
    })
    const [searchByEmail, setSearchByEmail] = useState("")
    const [nameSort, setNameSort] = useState(false)
    const [emailSort, setEmailSort] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState()
    const [matches, setMatches] = useState("")


    const greenBold = {
        fontWeight: 'bold',
        color: '#00B3B5'
    }

    useEffect(() =>{
        getApiData()
        getInactive()
    }, [filteredUsers])
   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // Function to collect data
    const getApiData = async () => {
        const response = await fetch(
        `${process.env.REACT_APP_ACTIVE_USR}`
        ).then((response) => response.json({}));
    
        // update the state
        setActiveUsers(response.user);
        
    };

  const getInactive = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_INACTIVE_USR}`
    ).then((response) => response.json({}));
  
    // update the state
    setInactiveUsers(response.inactiveuser);

    
  };

  const handleFilterChange = (event) => {
    setFilteredUsers()
    setFilter(event.target.value === 'true');
    if(!filter) {
        const filtered = activeUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }
    else {
        const filtered = inactiveUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }
};
function goToAccount(id) {
    navigate(`/editAccount/${id}`)
}


function sortName() {
    console.log("nameSort: " + nameSort)
    if(nameSort){
        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => b.name.localeCompare(a.name));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => b.name.localeCompare(a.name));
            setInactiveUsers(sorted);
        }
        setNameSort(false)
    }
    else {
        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => a.name.localeCompare(b.name));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => a.name.localeCompare(b.name));
            setInactiveUsers(sorted);
        }
        setNameSort(true)
    }
}

function sortEmail(){
    console.log("emailSort: " + emailSort)
    if(emailSort){
        setEmailSort(false)

        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => b.email.localeCompare(a.email));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => b.email.localeCompare(a.email));
            setInactiveUsers(sorted);
        }
    }
    else {
        setEmailSort(true)

        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => a.email.localeCompare(b.email));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => a.email.localeCompare(b.email));
            setInactiveUsers(sorted);
        }
    }
}
const search = () => {
    if(filter) {
        const filtered = activeUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }
    else {
        const filtered = inactiveUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }

}

const searchInputStyle = {
    float: 'right',
    margin: '16px',
    height: '28px',
    padding: '0px 12px'
}
const errorMessage = {
    position: 'absolute',
    top: '4%',
    fontWeight: 'bold',
    right: '5%',
    margin: '16px',
    height: '28px',
    padding: '0px 12px',
    color: 'red'
}
const dropdownStyle = {
    float: 'right',
    margin: '16px',
    height: '32px',
    padding: '0px 12px'
}
const searchButton = {
    float: 'right',
    margin: '16px 0px',
    height: '32px',
    padding: '0px 12px',
    backgroundColor: '#00B3B5',
    color: 'white',
    width: 'auto',
    border: 'none'
}
const caretStyle = {
    height: '16px',
    width: 'auto'
}

function returnUsers() {
    const activeUsersToDisplay = (filteredUsers !== undefined && filteredUsers?.length > 0) ? filteredUsers : activeUsers;
    const inactiveUsersToDisplay = (filteredUsers !== undefined && filteredUsers?.length > 0) ? filteredUsers : inactiveUsers;

    if(filter) {
        return(
            <div>

                <h1>Active Users</h1>
                
                <table className={'activeUsers'}>
                    <thead>
                        <th onClick={() => sortName()}>Name <img style={caretStyle} src={nameSort ?  caretDown : caretUp}></img></th>
                        <th onClick={() => sortEmail()}>Email <img style={caretStyle} src={emailSort ? caretDown : caretUp}></img></th>
                        <th>Monthly Allotment</th>
                        <th>Additional Cost</th>
                        <th>Coupon</th>
                        <th>Actions</th>
                    </thead>
                
                    <tbody>

                        {activeUsersToDisplay && activeUsersToDisplay.map((user) => (
                        
                            <tr>
                                <td id={user.name}>{user.name}</td>       
                                <td id={user.email}>{user.email}</td>  
                                <td style={greenBold}>{user.monthly_allotment || 0}</td>
                                <td style={greenBold}>${user.additional_cost || 9.99}</td>
                                <td>{user.coupon || "-"}</td>
                                <td><button name="email" onClick={() => goToAccount(user._id)} className="adjustment-button">EDIT</button></td>                 
                            </tr>
                        
                        ))}
                    </tbody>

                </table>

            </div> 

        )
    }
    else {
        return (
           
            <div className={'usercontainer2'}>
                <h1>Inactive Users</h1>

                <div className="inactive">

                    <table className={'activeUsers'}>
                        <thead>
                            <th onClick={() => sortName()}>Name <img style={caretStyle} src={nameSort? caretDown : caretUp}></img></th>
                            <th onClick={() => sortEmail()}>Email <img style={caretStyle} src={emailSort? caretDown : caretUp}></img></th>
                            <th>Monthly Allotment</th>
                            <th>Additional Cost</th>
                            <th>Coupon</th>
                            <th>Actions</th>
                        </thead>
            
                        <tbody>

                            {inactiveUsersToDisplay && inactiveUsersToDisplay.map((user) => (
                            
                                <tr>
                                    <td id={user.name}>{user.name}</td>       
                                    <td id={user.email}>{user.email}</td>  
                                    <td>{user.monthly_allotment || 0}</td>
                                    <td>${user.additional_cost || 9.99}</td>
                                    <td>{user.coupon || "-"}</td>
                                    <td><button name="email" onClick={() => goToAccount(user._id)} className="adjustment-button">EDIT</button></td>                 
                                </tr>
                            
                            ))}
                        </tbody>

                    </table>

                </div>

            </div>
        )
    }
}
  
  
    return (  

        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>

                <div className={'admin-container'}>
                
                    <button style={searchButton} onClick={() => search()}>Search</button>
                    <input placeholder="email" className="users-select" style={searchInputStyle} type="text" onChange={(event) => setSearchByEmail((event.target.value).trim())}/>
                    {matches && <p style={errorMessage}>{matches}</p>}


                    <select className="users-select" style={dropdownStyle} name="filter" id="filter" onChange={handleFilterChange}>
                        <option value="true">Active Users</option>
                        <option value="false">Inactive Users</option>
                    </select>
                

                    {returnUsers()}

                </div>

            </div>
            
        </div>

    )
}

export default AdminDashboard