import { width } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../css/Accounts.css';
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import MainWrapper from '../wrappers/mainWrapper';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom';

import StripeContext from './stripeContext.jsx';
import SavedPaymentMethods from './savedPaymentMethods.jsx';
import CheckoutForm from './checkoutForm.jsx';
import SubscriptionForm from './subscriptionForm.jsx';

import "../../css/App.css";

const Accounts = props => {
    const [customerId, setCustomerId] = useState("");
    let getUserId = useSelector(state => state.user)

    const [openCompany, setOpenCompany] = useState(false)
    const [openACCType, setOpenACCType] = useState(false)
    const [openPhoneNumber, setOpenPhoneNumber] = useState(false)
    const [companyInformation, setCompanyInformation] = useState({
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zipCode: ""
    })

    const handleCustomerId = (id) => {
        setCustomerId(id);
    };

    const [accType, setAccType] = useState({acc_type: ""})
    const [phoneNumber, setPhoneNumber] = useState({phone: ""})
    const [userData, setUserData] = useState()
    const [initialResponse, setInitialResponse] = useState(true)

    
    useEffect(() => {
        
        if(initialResponse){
            getUser(getUserId._id)
            setInitialResponse(false)
        }
      
        if(!initialResponse && userData?.company !== undefined) {
            setPhoneNumber({phone: userData.phone})
            setAccType({acc_type: userData.acc_type})
            setCompanyInformation({name: userData?.company.name, address_1: userData?.company.address_1, address_2: userData?.company.address_2, city: userData?.company?.city, state: userData?.company?.state, zipCode: userData?.company?.zipCode})
        }

       
      }, [userData, customerId])

    const getUser = async (id) => {
    const response = await fetch(
        `${process.env.REACT_APP_GET_USER_DATA}${id}`
    ).then((response) => response.json({}));
    setUserData(response)
    }
    
    function handleSaveCompany(id) {
        setOpenCompany(false)
        axios.put(`${process.env.REACT_APP_SAVE_COMPANY}${id}`, companyInformation)
          .then(response => {   
            getUser(getUserId._id)
          })
          .catch(error => {
            alert("Saving was unsuccessful. Please try again.");
            console.error('There was an error!', error);
          });
    }

    function handleSavePhoneNumber(id) {
        setOpenPhoneNumber(false)
        axios.put(`${process.env.REACT_APP_SAVE_PHONE_NUMBER}${id}`, phoneNumber)
          .then(response => {   
            getUser(getUserId._id)
          })
          .catch(error => {
            alert("Saving was unsuccessful. Please try again.");
            console.error('There was an error!', error);
          });
    }

    function handleSaveACCType(id) {
        setOpenACCType(false)
        axios.put(`${process.env.REACT_APP_SAVE_ACC_TYPE}${id}`, accType)
          .then(response => {   
            getUser(getUserId._id)
          })
          .catch(error => {
            alert("Saving was unsuccessful. Please try again.");
            console.error('There was an error!', error);
          });
    }

    function returnVal(value){
        if(value === undefined) {
            return
        }
        else {
            return(
                value
            )
        }
    }   
   

    function returnProfileContainer() {
        if(userData === undefined) {
            return(
                <div className={'profile-container'}>
                    <h1>One Moment Please.</h1>
                </div>
            )
        }
        else {
            return(
                <div className={'profile-container'}>
                    <Modal
                        ariaHideApp={false}
                        style={modalRestyleSmall}
                        isOpen={openACCType}
                        onRequestClose={() => setOpenACCType(false)}
                    >

                        <div style={modalStyle}>
                            <h1>Edit Account Type</h1>
                        </div>

                        <div style={centerFields}>
                            <FormControl variant="outlined" margin="normal" size="small" style={textFieldStyle}>
                                <InputLabel id="acc_type-label">Account Type</InputLabel>
                                <Select
                                    labelId="acc_type-label"
                                    id="acc_type"
                                    name="acc_type"
                                    defaultValue={returnVal(userData.acc_type)}
                                    onBlur={(e) => setAccType({acc_type: e.target.value.trim()})}                                    
                                    label="Account Type"
                                >
                                    <MenuItem value="Contractor">Contractor</MenuItem>
                                    <MenuItem value="Adjuster">Adjuster</MenuItem>
                                </Select>
                            </FormControl>
                        </div>                    

                        <div style={buttonContainer}>
                            <Button
                                size="small"
                                sx={{
                                ':hover': {
                                    backgroundColor: '#999999'
                                },
                                margin: '8px 8px', 
                                fontWeight: 'bold', 
                                color: 'white', 
                                backgroundColor: '#723E49'}}
                                onClick={() => setOpenACCType(false)}
                            >
                                Exit
                            </Button>
                            <Button
                                size="small"
                                sx={{
                                ':hover': {
                                    backgroundColor: '#999999'
                                },
                                margin: '8px 8px', 
                                fontWeight: 'bold', 
                                color: 'white', 
                                backgroundColor: '#00B3B5'}}
                                onClick={() => handleSaveACCType(userData._id)}
                            >
                                Save
                            </Button>
                        </div>    

                    </Modal>

                    <Modal
                        ariaHideApp={false}
                        style={modalRestyleLarge}
                        isOpen={openCompany}
                        onRequestClose={() => setOpenCompany(false)}
                    > 

                        <div style={modalStyle}>
                            <h1>Edit Company Information</h1>
                        </div>

                        <div style={centerFields}>
                            <TextField
                                style={textFieldStyle}
                                label={"Company Name"}
                                defaultValue={returnVal(userData.company?.name)}
                                onBlur={(e) => setCompanyInformation({...companyInformation, name: e.target.value.trim()})}
                            />
                            <TextField
                                style={textFieldStyle}
                                label={"Address Line 1"}
                                defaultValue={returnVal(userData.company?.address_1)}
                                onBlur={(e) => setCompanyInformation({...companyInformation, address_1: e.target.value.trim()})}
                            />
                            <TextField
                                style={textFieldStyle}
                                label={"Address Line 2"}
                                defaultValue={returnVal(userData.company?.address_2)}
                                onBlur={(e) => setCompanyInformation({...companyInformation, address_2: e.target.value.trim()})}
                            />
                            <TextField
                                style={textFieldStyle}
                                label={"City"}
                                defaultValue={returnVal(userData.company?.city)}
                                onBlur={(e) => setCompanyInformation({...companyInformation, city: e.target.value.trim()})}
                            />
                            <TextField
                                style={textFieldStyle}
                                label={"State"}
                                defaultValue={returnVal(userData.company?.state)}
                                onBlur={(e) => setCompanyInformation({...companyInformation, state: e.target.value.trim()})}
                            />
                            <TextField
                                style={textFieldStyle}
                                label={"Zip Code"}
                                defaultValue={returnVal(userData.company?.zipCode)}
                                onBlur={(e) => setCompanyInformation({...companyInformation, zipCode: e.target.value.trim()})}
                            />
                        </div>

                        <div style={buttonContainer}>
                            <Button
                                size="small"
                                sx={{
                                ':hover': {
                                    backgroundColor: '#999999'
                                },
                                fontWeight: 'bold', 
                                color: 'white',
                                margin: '8px 8px',
                                backgroundColor: '#723E49'}}
                                onClick={() => setOpenCompany(false)}
                            >
                                Exit
                            </Button>
                            <Button
                                size="small"
                                sx={{
                                ':hover': {
                                    backgroundColor: '#999999'
                                },
                                fontWeight: 'bold',
                                margin: '8px 8px',
                                color: 'white', 
                                backgroundColor: '#00B3B5'}}
                                onClick={() => handleSaveCompany(userData._id)}
                            >
                                Save
                            </Button>
                        </div>
                        

                    </Modal>
                    
                    <Modal
                        ariaHideApp={false}
                        style={modalRestyleSmall}
                        isOpen={openPhoneNumber}
                        onRequestClose={() => setOpenPhoneNumber(false)}
                    >

                        <div style={modalStyle}>
                            <h1>Edit Phone Number</h1>
                        </div>
                        
                        <div style={centerFields}>
                            <TextField
                                style={textFieldStyle}
                                label={"Phone Number"}
                                defaultValue={returnVal(userData.phone)}
                                onBlur={(e) => setPhoneNumber({phone: e.target.value.trim()})}
                            />
                        </div>

                        <div style={buttonContainer}>
                            <Button
                                size="small"
                                sx={{
                                ':hover': {
                                    backgroundColor: '#999999'
                                },
                                margin: '8px 8px',
                                fontWeight: 'bold', 
                                color: 'white', 
                                backgroundColor: '#723E49'}}
                                onClick={() => setOpenPhoneNumber(false)}
                            >
                                Exit
                            </Button>

                            <Button
                                size="small"
                                sx={{
                                ':hover': {
                                    backgroundColor: '#999999'
                                },
                                fontWeight: 'bold',
                                margin: '8px 8px',
                                color: 'white', 
                                backgroundColor: '#00B3B5'}}
                                onClick={() => handleSavePhoneNumber(userData._id)}
                            >
                                Save
                            </Button>
                        </div>
                    
                    </Modal>

                    

                    <div className={'account-details'}>

                        <div
                            style={{width: '40%'}}
                        >
                            <div>
                                <h2 className={'green-large-bold'}>ACCOUNT DETAILS</h2>
                                <h1 className={'h1-style'}>{returnVal(userData?.name) + " " + userData?.lastName}</h1>
                                <p className={'black-small'}>{returnVal(userData?.email)}</p>
                            </div>
                            
                            <div style={{marginTop: '12px'}}>
                                <h2 className={'green-small-bold'}>COMPANY</h2>
                                <Button
                                        style={editButton}
                                        size="small"
                                        onClick={()=> setOpenCompany(true)}
                                    >
                                        Edit
                                </Button>
                                <p className={'black-small-bold'}>{returnVal(userData.company?.name)}</p>
                                <p className={'black-small'}>
                                    {returnVal(userData.company?.address_1)} 
                                </p>
                                <p className={'black-small'}>
                                    {returnVal(userData.company?.address_2)} 
                                </p>
                                <p className={'black-small'}>
                                    {returnVal(userData.company?.city)}, {returnVal(userData.company?.state)} &nbsp; {returnVal(userData.company?.zipCode)}
                                </p>
                                
                            </div>

                        
                            <div style={{marginTop: '12px'}}>
                                <h2 className={'green-small-bold'}>ACCOUNT TYPE</h2>
                                    <Button
                                        style={editButton}
                                        size="small"
                                        onClick={() => setOpenACCType(true)}
                                    >
                                        Edit
                                    </Button>
                                <p className={'black-small-bold'}>
                                    {userData.acc_type}
                                    
                                </p>
                            </div>

                            <div style={{marginTop: '12px'}}>
                                <h2 className={'green-small-bold'}>PHONE NUMBER</h2>
                                    <Button
                                        style={editButton}
                                        size="small"
                                        onClick={() => setOpenPhoneNumber(true)}
                                    >
                                        Edit
                                    </Button>
                                <p className={'black-small-bold'}>
                                    {returnVal(userData.phone)}
                                </p>
                            </div>
                        </div>
                        
                        <div style={{width: '60%'}}>
                        <StripeContext>
                            {customerId && <SavedPaymentMethods customerId={customerId} />}
                            <CheckoutForm onCustomerId={handleCustomerId} existingCustomerId={handleCustomerId} />
                            <SubscriptionForm/>
                        </StripeContext>
                        </div>

                       
            
                    </div>

                    <div className={'cancel-subscription'}></div>
                
                    
                </div>
            )
        }
    }



    const editButton = {
        color: 'blue',
        fontSize: '10px'
    }

    const modalStyle = {
        textAlign: 'center',
        background: 'none',
        marginBottom: '12px'
    }

    const modalRestyleSmall = {
        overlay: {
            top: '25%',
            left: '25%',
            width: '50%',
            height: '30%',
            position: 'fixed',
        },
        content: {
            inset: '5%',
            border: '1px solid black',
            backgroundColor: '#DDDDDD'
        }
    }

    const modalRestyleLarge = {
        overlay: {
            top: '25%',
            left: '25%',
            width: '50%',
            height: '70%',
            position: 'fixed',
        },
        content: {
            inset: '5%',
            border: '1px solid black',
            backgroundColor: '#DDDDDD'
        }
    }

    const buttonContainer = {
        display: 'flex',
        flexDirection: 'column',
        padding: '4px 4px',
        width: '94%',
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: '5%',
        marginTop: '12px'
    }

    const textFieldStyle = {
        display: 'grid',
        padding: '2px',
        backgroundColor: 'white',
        margin: '12px',
        borderRadius: '4px'
    }

    const centerFields = {
        alignItems: 'middle'
    }

    return (
        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>
                {returnProfileContainer()}
            </div>
        </div>
    )
}

export default Accounts
